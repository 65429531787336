import {
  createSignal,
  For,
  Show,
  Suspense,
  ErrorBoundary,
  createMemo,
} from "solid-js";
import { useAction, createAsync, useNavigate } from "@solidjs/router";
import { createForm } from "@felte/solid";
import { validator } from "@felte/validator-zod";
import * as z from "zod";
import {
  getSMSNumbers,
  useSMSNumbers,
  updateWhitelistNumberAction,
  addWhitelistNumberAction,
  deleteWhitelistNumberAction,
} from "~/services/sms";
import Button from "~/components/Button";
import {
  checkBadge,
  cog_6Tooth,
  devicePhoneMobile,
  xMark,
} from "solid-heroicons/outline";
import { TextFieldInput, MultiSelect, Checkbox } from "~/components/inputs";
import { formatPhoneNumber } from "~/utils/helpers";
import { Icon } from "solid-heroicons";
import { BaseSkeleton, Loader } from "~/components/utility";
import { Modal } from "~/components/utility";
import { AccountPanel } from "~/components/account";
import { usePrompt } from "~/components/utility";
import { SMSNumber } from "~/services/roma-api/sms/types";
import { useErrorContext } from "~/utils/contexts";
import { checkError } from "~/services/roma-api/errors";

const isNumeric = (value: string) => /^\d+$/.test(value);

const reqNonEmpty = (max?: number) => {
  return z
    .string()
    .min(1, { message: "This field cannot be empty" })
    .max(max ?? 40, {
      message: `Must be less than ${max ?? "40"} characters long.`,
    });
};
const schema = z
  .object({
    // PhoneNumber: reqNonEmpty(50),
    AreaCode: reqNonEmpty(3),
    Exchange: reqNonEmpty(3),
    LineNumber: reqNonEmpty(4),
    Name: reqNonEmpty(50),
  })
  .refine(({ AreaCode }) => isNumeric(AreaCode), {
    message: "Must be a number",
    path: ["AreaCode"],
  })
  .refine(({ Exchange }) => isNumeric(Exchange), {
    message: "Must be a number",
    path: ["Exchange"],
  })
  .refine(({ LineNumber }) => isNumeric(LineNumber), {
    message: "Must be a number",
    path: ["LineNumber"],
  });

export default function Mobile() {
  const { addError } = useErrorContext();
  const navigate = useNavigate();

  // TODO - usecreateAsync, catch error
  // const numbers = useSMSNumbers();
  const numbers = createAsync(async () => {
    try {
      return await getSMSNumbers();
    } catch (error) {
      const err = checkError(error);
      if (import.meta.env.DEV) {
        console.log("[mobile.tsx]: Error caught in createAsync: ", err);
      }
      addError(err, {
        severity: "critical",
        showDetails: true,
        title: "An error occurred",
        message:
          "An error occurred while retrieving your registered phone numbers. Please try again. If this error persists, kindly contact support. We apologize for the inconvenience.",
        actions: [
          {
            label: "Refresh",
            action: () => {
              window.location.reload();
            },
          },
          {
            label: "Contact Support",
            action: async () => {
              navigate("/support");
            },
          },
        ],
      });
    }
  });
  const [Prompt, openPrompt] = usePrompt();
  const defaultSet = ["Inventory", "Pricing", "Representative", "Orders"];
  const [modalState, setModalState] = createSignal<"add" | "edit" | "closed">(
    "closed"
  );
  const [response, setResponse] = createSignal<string>();
  const [loadingItem, setLoadingItem] = createSignal<string>();
  const [selection, setSelection] = createSignal<Set<string>>(
    new Set(defaultSet)
  );

  // resolves occasional hydration issue..calling reverse() in th <For>
  // was problematic - reverse() creates a new Array each call? so server/client didn't
  // match up? === hydration issue?
  const numbersList = createMemo(() => {
    if (numbers() && numbers()?.Results) {
      return numbers()?.Results.reverse();
    }
    return [];
  });

  // * ACTIONS * //

  const addNum = useAction(addWhitelistNumberAction);
  const updateNum = useAction(updateWhitelistNumberAction);
  const deleteNum = useAction(deleteWhitelistNumberAction);

  // * FUNCTIONS  * //

  const removePhone = async (id: string) => {
    const ok = await openPrompt({
      title: "Confirm Number Deletion",
      description: "Are you sure you want to delete this number?",
      requireInteraction: true,
      options: [
        { label: "Cancel", value: false, priority: true },
        { label: "Delete Number", value: true, style: "red" },
      ],
    });

    if (ok) {
      try {
        setLoadingItem(id);
        await deleteNum(id);
        setLoadingItem(undefined);
      } catch (error) {
        setLoadingItem(undefined);
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log("[removePhone]: Caught error: ", err);
        }

        addError(err, {
          severity: "critical",
          showDetails: true,
          title: "An error occurred",
          message:
            "An error occurred while deleting this number. Please try again. If this error persists, kindly contact support. We apologize for the inconvenience.",
          actions: [
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
      }
    }
  };
  const editPhone = async (number: SMSNumber) => {
    const permissions = new Set<string>();
    if (number.Inventory) permissions.add("Inventory");
    if (number.Pricing) permissions.add("Pricing");
    if (number.Representative) permissions.add("Representative");
    if (number.Orders) permissions.add("Orders");
    setModalState("edit");
    setSelection(permissions);
    setData((data) => ({ ...data, ...number }));
    // destructure the phone number and set on form store:
    const [, CountryCode, AreaCode, Exchange, LineNumber] =
      number.PhoneNumber.match(
        /^(\+\d{1})(\d{3})(\d{3})(\d{4})$/
      ) as RegExpMatchArray;
    setData((data) => ({
      ...data,
      CountryCode,
      AreaCode,
      Exchange,
      LineNumber,
    }));
  };

  // * FORM * //

  const {
    form,
    isSubmitting,
    reset: resetForm,
    data,
    setData,
    isValid,
    errors,
  } = createForm<
    SMSNumber & {
      SendWelcome: boolean;
      Promos: boolean;
      CountryCode: string;
      AreaCode: string;
      Exchange: string;
      LineNumber: string;
    }
  >({
    extend: validator({ schema }),
    onSubmit: async (values) => {
      setResponse(undefined);
      const selections = selection();
      // const PhoneNumber = values.PhoneNumber.replace(/[^\d+]/g, "");
      const PhoneNumber =
        values.CountryCode.trim() +
        values.AreaCode.trim() +
        values.Exchange.trim() +
        values.LineNumber.trim();

      const payload = {
        Pricing: selections.has("Pricing") ? true : false,
        Inventory: selections.has("Inventory") ? true : false,
        Representative: selections.has("Representative") ? true : false,
        Orders: selections.has("Orders") ? true : false,
        Name: values.Name,
        SendWelcome: values.SendWelcome,
        PhoneNumber: PhoneNumber,
        Promos: values.Promos,
      };

      try {
        const response = await (values.ID
          ? updateNum(payload, values.ID)
          : addNum(payload));

        // ? TODO - updateNum may get a response body, currently empty
        if (response && response?.ID) {
          resetForm();
          setModalState("closed");
          setSelection(new Set(defaultSet));
        }
      } catch (error: any) {
        // TODO - Error handling..
        switch (error.Code) {
          case "NOT_MOBILE":
            setResponse("Not a valid mobile number.");
            break;
          case "INVALID_COUNTRY":
            setResponse("Invalid country.");
            break;
          default:
            setResponse("An error occurred.");
        }
        const err = checkError(error);
        addError(err, {
          severity: "critical",
          showDetails: true,
          title: "Submission Error",
          message: `An error occurred while ${
            values.ID ? "updating" : "adding"
          } this number. Please try again. If the error persists, kindly contact customer support. We apologize for the inconvenience.`,
          actions: [
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
      }
    },
  });
  form;

  return (
    <AccountPanel>
      <div class="max-w-2xl">
        <h2 class="text-3xl font-bold">SMS Mobile Service</h2>
        <div class="mt-10">
          <p>
            In an effort to service you better, Roma is pleased to offer direct
            access to instant inventory, pricing and more via our toll-free
            1-888-851-ROMA (7662) text-message service. To begin using this
            service register your mobile phone number below.
          </p>
          <p>Standard text message rates apply per carrier.</p>
        </div>
      </div>

      <Suspense fallback={<BaseSkeleton height={400} />}>
        <main class="pb-10">
          <div class="divide-y pt-4 child:py-8">
            <section class="flex flex-col gap-6">
              <h3 class="text-lg font-bold">Active Mobile Numbers</h3>
              <div class="flex justify-end">
                <Button
                  onClick={() => setModalState("add")}
                  class="w-52"
                  style="outline"
                >
                  Add Number
                </Button>
              </div>

              <Show
                fallback={
                  <div class="flex justify-center items-center border p-20">
                    You have no mobile numbers registered.
                  </div>
                }
                when={numbers()?.Total !== 0}
              >
                <table class="table-auto w-full">
                  <thead class="bg-roma-grey text-xs text-roma-dark-grey font-normal border-b ">
                    <tr class="child:font-medium child:py-2 sticky top-0 z-10 backdrop-blur-sm">
                      <th class=" z-10 w-2/12">Name</th>
                      <th class=" z-10 w-2/12">Mobile Number</th>
                      <th class=" z-10 w-4/12">Services</th>
                      <th class=" z-10  w-1/12">Actions</th>
                    </tr>
                  </thead>
                  <tbody class="table-auto text-center">
                    <For each={numbersList()}>
                      {(number) => (
                        <tr class="child:font-medium border-b border-gray-100 hover:border-gray-100 last:border-none child:py-4  top-0 z-10">
                          <td class="text-left pl-3">
                            {number.Name}
                            <div class="text-xs text-gray-400">
                              Added {new Date(number.CreatedAt).toDateString()}
                            </div>
                            <Show when={number.CustomerID != ""}>
                              <div class="text-xs text-roma-blue">
                                Customer #{number.CustomerID}
                              </div>
                            </Show>
                          </td>
                          <td>{formatPhoneNumber(number.PhoneNumber)}</td>
                          <td>
                            <div class="flex justify-center gap-1 flex-wrap">
                              <Show when={number.Pricing}>
                                <div class="rounded-3xl text-xs bg-gray-200 p-2 px-4">
                                  Pricing
                                </div>
                              </Show>
                              <Show when={number.Inventory}>
                                <div class="rounded-3xl text-xs bg-gray-200 p-2 px-4">
                                  Inventory
                                </div>
                              </Show>
                              <Show when={number.Representative}>
                                <div class="rounded-3xl text-xs bg-gray-200 p-2 px-4">
                                  Representative
                                </div>
                              </Show>
                              <Show when={number.Orders}>
                                <div class="rounded-3xl text-xs bg-gray-200 p-2 px-4">
                                  Orders
                                </div>
                              </Show>
                            </div>
                          </td>
                          <td class="flex gap-1 justify-center">
                            <button
                              aria-label="Edit Phone Number"
                              disabled={loadingItem() == number.ID}
                              title="Edit item"
                              class="p-1.5 text-roma-dark-grey hover:text-roma-blue transition-[color] border border-gray-300 rounded-md"
                              onClick={() => editPhone(number)}
                            >
                              <Icon path={cog_6Tooth} class="w-5" />
                            </button>
                            <button
                              aria-label="Delete Phone Number"
                              disabled={loadingItem() == number.ID}
                              title="Delete item"
                              class="p-1.5 text-roma-dark-grey hover:text-red-500 transition-[color] border border-gray-300 rounded-md"
                              onClick={() => removePhone(number.ID)}
                            >
                              <Icon path={xMark} class="w-5" />
                            </button>
                          </td>
                        </tr>
                      )}
                    </For>
                  </tbody>
                </table>
              </Show>
            </section>
            <Modal
              open={() => modalState() !== "closed"}
              onClose={() => {
                setModalState("closed"), setResponse(undefined);
              }}
            >
              <section class="flex flex-col">
                <h3 class="text-base font-bold">
                  {data().ID ? "Edit" : "Add"} A Number
                </h3>
                <Show when={response()}>
                  <div class="border border-red-500 p-3 mt-3 text-red-500 rounded-lg text-sm">
                    {response()}
                  </div>
                </Show>
                <form use:form class="flex flex-col space-y-4">
                  <input class="hidden" type="hidden" value={data().ID} />
                  <TextFieldInput
                    name="Name"
                    value={data().Name}
                    error={errors().Name}
                    validationState={errors().Name ? "invalid" : "valid"}
                    placeholder="Mobile owner name"
                  />
                  <div class="flex items-center gap-2">
                    <Icon
                      path={devicePhoneMobile}
                      class="w-7 text-roma-medium-grey"
                    />
                    <TextFieldInput
                      type="phone"
                      name="CountryCode"
                      value="+1"
                      disabled
                      rootClass="w-[45px]"
                    />
                    <TextFieldInput
                      type="phone"
                      name="AreaCode"
                      value={data().AreaCode}
                      rootClass="w-[60px] shrink"
                      placeholder="(555)"
                      maxLength={3}
                      error={errors().AreaCode}
                      validationState={errors().AreaCode ? "invalid" : "valid"}
                    />
                    <TextFieldInput
                      type="phone"
                      name="Exchange"
                      value={data().Exchange}
                      rootClass="w-[60px]"
                      placeholder="123"
                      maxLength={3}
                      error={errors().Exchange}
                      validationState={errors().Exchange ? "invalid" : "valid"}
                    />
                    <span>-</span>
                    <TextFieldInput
                      type="phone"
                      name="LineNumber"
                      value={data().LineNumber}
                      rootClass="w-[80px]"
                      placeholder="4567"
                      maxLength={4}
                      error={errors().LineNumber}
                      validationState={
                        errors().LineNumber ? "invalid" : "valid"
                      }
                    />
                  </div>
                  <div class="text-xs text-gray-500 px-3 mt-1">
                    Only mobile phone numbers registered in Canada and the USA
                    are allowed.
                  </div>
                  <MultiSelect
                    name="Services"
                    placeholder="Select allowed services..."
                    options={[
                      "Inventory",
                      "Pricing",
                      "Representative",
                      "Orders",
                    ]}
                    value={Array.from(selection())}
                    onChange={(val: any) => {
                      const set = new Set(val as string[]);
                      setSelection(set);
                    }}
                    icon={checkBadge}
                  />
                  <Checkbox
                    name="SendWelcome"
                    label="Send a welcome message."
                    defaultChecked={modalState() === "edit" ? false : true}
                    checked={data()?.SendWelcome}
                    onChange={(val) => setData("SendWelcome", val)}
                  />
                  <Checkbox
                    name="Promos"
                    label="I would like to receive updates regarding Roma Moulding promotions and products"
                    defaultChecked
                    checked={data()?.Promos}
                    onChange={(val) => setData("Promos", val)}
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting() || !isValid()}
                    class="w-full text-sm"
                  >
                    {data().ID ? "Edit" : "Add"} Number
                  </Button>
                </form>
              </section>
            </Modal>
          </div>
        </main>
        {Prompt}
      </Suspense>
    </AccountPanel>
  );
}
